import React, { useState } from 'react';
// import Layout from '../components/CategoryLayout';
import parseDate from '../utils/parseDate';


import { graphql } from "gatsby"

import "../theme/layout.scss"
import CustomFooter from '../components/CustomFooter';
import GdprCookies from '../components/GdprCookies';
// import HeaderNew from '../components/HeaderNew';
import Header from '../components/layout/HeaderLayout';
import SearchIcon from '@material-ui/icons/Search';
import MercadosBg from '../assets/mercados-bg.jpg';


import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import ModalSuscribe from '../components/layout/ModalSuscribre';

const Mercados = ({data }:any) =>{

    const mercadosALaApertura = data.mercadosALaApertura.edges;
    const hig = mercadosALaApertura[0];

    //Pagination
    const [currentPage, setCurrentPage] = useState(1);
    const todosPerPage = 5;
    const totalCards = mercadosALaApertura.slice(1);
    const indexOfLastTodo = (currentPage * todosPerPage);
    const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
    const cards = totalCards.slice(indexOfFirstTodo,indexOfLastTodo);
    const totalPages = Math.ceil(totalCards.length / todosPerPage);

    //functions modal suscribe
    const [open, setOpen] = useState(false);
    function openSuscribe(){
      setOpen(true);
    }
    function closeSuscribe(){
      setOpen(false);
    }

    return (
      <>
        <Header suscribe={true} openModal={openSuscribe}/>
        <div className="header-section-bg">

          <div className='top-bg' style={{ backgroundImage: `url(${MercadosBg})` }}></div>

          <div className="container-sec">

            <div className='top-content'>
              <div className='top-title'>
                <h2>Mercados a la Apertura</h2>
              </div>
              <div className='top-search'>
                <form action="/buscar">
                  <input type="text" name='q' />
                  <SearchIcon className='icon--search' fontSize="small"/>  
                </form>
              </div>
            </div>

            <div className="top-item">
              <div className="top-tag">
                <p>documento destacado</p>
              </div>

              <div className="top-card">
                  <p className="date">{parseDate(hig.node.createdAt)}</p>
                  <h2 className="title">{hig.node.titulo}</h2>
                  <p className="resume-content">{hig.node.resumen}</p>
                  <div className='item--footer'>
                    <p className="autor">{hig.node.autor.nombre}</p>
                    <a href={`/mercados-a-la-apertura/${hig.node.slug}`} >
                        <div className="link">Ver más</div>
                    </a>
                  </div>
              </div>

            </div>



          </div>

        </div>
        <div className='wrap-content'>

          {cards && cards.map(({ node, index}:any) => {
            const { titulo, resumen, slug, createdAt, autor} = node
            return (
              
              <div key={index} className="item">
                  <p className="date">{parseDate(createdAt)}</p>
                  <h2 className="title">{titulo}</h2>
                  <p className="resume-content">{resumen}</p>
                  <div className='item--footer'>
                    <p className="autor">{autor.nombre}</p>
                    <a href={`/mercados-a-la-apertura/${slug}`} >
                        <div className="link">Ver más</div>
                    </a>
                  </div>
              </div>
            )
          
          })}
          <div className='wrap-pagination'>
            <div className='pagination'>
              <FirstPageIcon fontSize='small' onClick={()=>setCurrentPage(1)}/>
              <NavigateBeforeIcon fontSize='small' onClick={()=>{
                if(currentPage > 1){
                  setCurrentPage(currentPage-1)
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <div className='text'>{currentPage}</div>
              <div className='text de'>de</div>
              <div className='text'>{totalPages}</div>
              <NavigateNextIcon fontSize='small' onClick={()=>{
                if(currentPage < totalPages ){
                  setCurrentPage(currentPage+1);
                }
                console.log("index of", indexOfLastTodo)
                console.log("index first", indexOfFirstTodo)
              }}/>
              <LastPageIcon fontSize='small' onClick={()=>setCurrentPage(totalPages)}/>
            </div>
          </div>
        </div>
        
        <CustomFooter openModal={openSuscribe}/>
        <GdprCookies />
        {
          open && <ModalSuscribe closeModal={closeSuscribe}/>
        }
      </>

            
    )
}

export default Mercados;

export const pageQuery = graphql`
query MyQueryMercados{  
    mercadosALaApertura: allContentfulMercadosALaApertura(
        sort: { fields: createdAt, order: DESC }
      ) {
        edges {
          node {
            slug
            titulo
            resumen
            createdAt
            autor {
                nombre
            }
          }
        }
      }
      

  }
`;
